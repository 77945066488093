import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useUserApi } from "@/api/user";
import { allPrivateRoutes } from "@/router";
import { authActions, authSelectors } from "@/store/slices/auth";
import { getAccessibleRoutes } from "@/utils/routeUtils";

const RawErrorPage = ({ className, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const profileUuid = useSelector((state) => state.auth.uuid);
  const { getUserProfile } = useUserApi();

  const [firstAccessibleRoute, setFirstAccessibleRoute] = useState(null);

  useEffect(() => {
    if (isLoggedIn) getProfile();
  }, [isLoggedIn]);

  const getProfile = async () => {
    try {
      const res = await getUserProfile(profileUuid);
      if (res.status !== "ok") return;

      const userMainRole = res.result?.userRole?.role?.code;
      const userSubRoles = res.result?.userRole
        ? Object.entries(res.result.userRole)
            .filter(
              ([key, value]) =>
                [
                  "isRequester",
                  "isReviewer",
                  "isApprover",
                  "isVisaApprover",
                ].includes(key) && value === true
            )
            .map(([key]) => key)
        : [];

      const userRoles = [userMainRole, ...userSubRoles];

      dispatch(authActions.setProfileObj(res.result));
      dispatch(authActions.setUserRoles(userRoles));

      if (userRoles?.length) {
        const allAccessibleRoutes = getAccessibleRoutes(
          allPrivateRoutes,
          userRoles
        );
        setFirstAccessibleRoute(`/${allAccessibleRoutes[0].path}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={className}>
      <div className="wscn-http404">
        <div className="error">
          <div className="error__oops">
            <span>{t("error_page.oops")}</span>
          </div>
          <div className="error__headline">
            {t("error_page.page_not_found")}
          </div>
          <a
            href={isLoggedIn ? firstAccessibleRoute : "/sign-in"}
            className="error__return-home"
          >
            {t("error_page.back_to_home")}
          </a>
        </div>
      </div>
    </div>
  );
};

const ErrorPage = styled(RawErrorPage)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  color: var(--default-font-color);

  .mobile {
    top: 15%;
    left: 0;
  }

  .wscn-http404 {
    position: relative;
    width: 1200px;
    padding: 0 50px;
    overflow: hidden;
    .error__headline {
      margin: 2rem auto;
    }
    .error__oops {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0 !important;
    }
    .pic-404 {
      position: relative;
      float: left;
      width: 600px;
      overflow: hidden;
      height: 140px;
      background-size: 140px;
      &__parent {
        width: 100%;
      }
      &__child {
        position: absolute;
        &.left {
          width: 80px;
          top: 17px;
          left: 220px;
          opacity: 0;
          animation-name: cloudLeft;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
          animation-delay: 1s;
        }
        &.mid {
          width: 46px;
          top: 10px;
          left: 420px;
          opacity: 0;
          animation-name: cloudMid;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
          animation-delay: 1.2s;
        }
        &.right {
          width: 62px;
          top: 100px;
          left: 500px;
          opacity: 0;
          animation-name: cloudRight;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
          animation-delay: 1s;
        }
        @keyframes cloudLeft {
          0% {
            top: 17px;
            left: 220px;
            opacity: 0;
          }
          20% {
            top: 33px;
            left: 188px;
            opacity: 1;
          }
          80% {
            top: 81px;
            left: 92px;
            opacity: 1;
          }
          100% {
            top: 97px;
            left: 60px;
            opacity: 0;
          }
        }
        @keyframes cloudMid {
          0% {
            top: 10px;
            left: 420px;
            opacity: 0;
          }
          20% {
            top: 40px;
            left: 360px;
            opacity: 1;
          }
          70% {
            top: 130px;
            left: 180px;
            opacity: 1;
          }
          100% {
            top: 160px;
            left: 120px;
            opacity: 0;
          }
        }
        @keyframes cloudRight {
          0% {
            top: 100px;
            left: 500px;
            opacity: 0;
          }
          20% {
            top: 120px;
            left: 460px;
            opacity: 1;
          }
          80% {
            top: 180px;
            left: 340px;
            opacity: 1;
          }
          100% {
            top: 200px;
            left: 300px;
            opacity: 0;
          }
        }
      }
    }
    .error {
      position: relative;
      float: left;
      width: 300px;
      padding: 30px 0;
      overflow: hidden;
      &__oops {
        font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        opacity: 0;
        margin-bottom: 20px;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
      &__headline {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        opacity: 0;
        margin-bottom: 10px;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
      }
      &__info {
        font-size: 13px;
        line-height: 21px;
        color: grey;
        opacity: 0;
        margin-bottom: 30px;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
      }
      &__return-home {
        display: block;
        float: left;
        width: 110px;
        height: 36px;
        border-radius: 100px;
        text-align: center;
        color: var(--button-font-color);
        opacity: 0;
        font-size: 14px;
        line-height: 36px;
        cursor: pointer;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
      }
      @keyframes slideUp {
        0% {
          transform: translateY(60px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    .error__return-home {
      color: var(--primary-color);
    }
  }
`;

export default ErrorPage;
